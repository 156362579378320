
	// IE
	@mixin old-ie {
		html.old-ie {
			@content;
		}
	}

	@mixin ie8 {
		html.ie8 {
			@content;
		}
	}

	@mixin ie {
		html.ie {
			@content;
		}
	}